import React, { useEffect, useState } from "react";
import Routes from "./routes.js";
import { Button } from "components/atoms";
import { Modal } from "components/organisms";
import jwtDecode from "jwt-decode";
import "./assets/styles/main.scss";

const App = () => {
  const [showModalExpired, setShowModalExpired] = useState(false);
  const token = localStorage.getItem("userToken");

  useEffect(() => {
    checkToken();
  }, []);

  const checkToken = () => {
    if (token) {
      const tokenDecoded = jwtDecode(token);
      const tokenExpire = parseInt(tokenDecoded.exp) * 1000;
      const betweenTwoDate = tokenExpire - Date.now();

      if (betweenTwoDate <= 0) {
        setShowModalExpired(true);
      }

      if (betweenTwoDate > 1) {
        setTimeout(() => {
          setShowModalExpired(true);
        }, betweenTwoDate);
      }
    }
  };
  const logout = () => {
    localStorage.removeItem("userToken");
    window.location.href = "/login";
  };

  return (
    <>
      <Modal show={showModalExpired}>
        <div className="px-4 pt-2 pb-3">
          <h1>SESI KAMU SUDAH HABIS</h1>
          <div className="text-center mt-2">
            <Button
              type="button"
              className="px-4"
              color="red"
              radius="lg"
              onClick={logout}
              md
            >
              LOGIN
            </Button>
          </div>
        </div>
      </Modal>
      <Routes />
    </>
  );
};

export default App;
