import React, { useEffect, useReducer, useState } from "react";
import { Main } from "components/templates";
import { historyList, drinkList, foodList, totalSales } from "services/backend";
import Modal from "components/organisms/Modal";
import dayjs from "dayjs";
const socketServer = process.env.REACT_APP_SOCKET;
const intervalScheduler = 1;

const Page = () => {
  const typeList = ["DAILY", "MONTHLY", "YEARLY"];
  const [type, setType] = useState("DAILY");
  const [division, setDivision] = useState("BAR");
  const [isFetching, setIsFetching] = useState(false);
  const [dateNow, setDateNow] = useState(dayjs.utc(new Date()).format("YYYY-MM-DD"));
  const [filterParams, setFilterParams] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      page: 1,
      limit: 1500,
    }
  );
  const [salesData, setSalesData] = useState([]);
  const [history, setHistory] = useState([]);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [notaDetail, setNotaDetail] = useState({
    menu: [],
  });

  const onChangeDivision = (event) => {
    setDivision(event.target.value);
  };

  const onChangeType = (event) => {
    setType(event.target.value);
  };

  const onChangeDate = (type, value) => {
    console.log(type, value);
    setType(type);
    setDateNow(value);
  };

  useEffect(() => {
    getData();
  }, [division, dateNow, type]);

  const getData = async () => {
    setIsFetching(true);
    try {
      const response = await totalSales(dateNow, type, division);
      const data = response.data.results.data;
      setSalesData(data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsFetching(true);
    }
  };

  return (
    <Main>
      <div className="history px-4">
        <div className="history-header">
          <div className="history-title">SALES</div>
        </div>
        <div className="row mt-2">
          <div className="col-8">
            <div className="mb-4">
              <label>DIVISI</label>
              <select onChange={onChangeDivision} name="type" className="form-input" defaultValue={typeList[0]}>
                <option value="bar">BAR</option>
                <option value="kitchen">KITCHEN</option>
              </select>
            </div>
            <div className="mb-4">
              <label>LAPORAN</label>
              <select onChange={onChangeType} name="type" className="form-input" defaultValue={typeList[0]}>
                {
                  typeList.map((type, index) => (
                    <option
                      value={type}
                      key={index}
                    >
                      {type}
                    </option>
                  ))
                }
              </select>
            </div>
            {(() => {
              switch (type) {
              case "DAILY":
                return (
                  <div className="mb-4">
                    <label>TANGGAL</label>
                    <input onChange={(event) => onChangeDate(type, event.target.value)} value={dateNow} type="date" className="form-input" />
                  </div>
                );
              case "MONTHLY":
                return (
                  <div className="mb-4">
                    <label>BULAN</label>
                    <input
                      onChange={(event) => onChangeDate(type, event.target.value)}
                      value={`${String(dateNow).split("-")[0]}-${String(dateNow).split("-")[1]}`}
                      type="month"
                      className="form-input"
                    />
                  </div>
                );
              case "YEARLY":
                return (
                  <div className="mb-4">
                    <label>TAHUN</label>
                    <select
                      onChange={(event) => onChangeDate(type, event.target.value)}
                      name="type"
                      className="form-input"
                      defaultValue={typeList[0]}
                    >
                      {
                        ["2015", "2016", "2017", "2018", "2019", "2020", "2021", "2022"].map((type, index) => (
                          <option
                            value={type}
                            key={index}
                          >
                            {type}
                          </option>
                        ))
                      }
                    </select>
                  </div>
                );
              }
            })()}
          </div>
          <div className="col-16">
            <h2>HASIL</h2>
            <div className="list-sales">
              {
                salesData.length > 0
                  ? salesData.map(({ name, amount }, index) => (
                    <div key={index} className="sales">
                      <div className="sales-wrapper">
                        <div className="sales-rank">{index + 1}.</div>
                        <div className="sales-name">{name}</div>
                        <div className="sales-quantity">{amount}</div>
                      </div>
                    </div>
                  ))
                  : <h1 className="text-center">TIDAK ADA DATA</h1>
              }
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default Page;
