export const logout = (callback) => {
  localStorage.removeItem("userToken");
  localStorage.removeItem("userData");
  callback();
};

export const secondsToHMS = (d) => {
  d = Number(d);
  const h = Math.floor(d / 3600);
  const m = Math.floor(d % 3600 / 60);
  const s = Math.floor(d % 3600 % 60);

  const hDisplay = h > 0 ? h + (h === 1 ? " jam " : " jam ") : "";
  const mDisplay = m > 0 ? m + (m === 1 ? " menit " : " menit ") : "";
  const sDisplay = s > 0 ? s + (s === 1 ? " detik" : " detik") : "";
  return hDisplay + mDisplay + sDisplay;
};

export const timeSince = (dateNow, date) => {
  const currentDate = dateNow || new Date();
  const seconds = Math.floor((currentDate - new Date(date)) / 1000);
  let results;
  if ((seconds / 31536000) > 1) {
    results = Math.floor(seconds / 31536000) + " Years";
  } else if ((seconds / 2592000) > 1) {
    results = Math.floor(seconds / 2592000) + " Months";
  } else if ((seconds / 86400) > 1) {
    results = Math.floor(seconds / 86400) + " Days";
  } else if ((seconds / 3600) > 1) {
    results = Math.floor(seconds / 3600) + " Hours";
  } else if ((seconds / 60) > 1) {
    results = Math.floor(seconds / 60) + " Minutes";
  } else {
    results = Math.floor(seconds) + " Seconds";
  }

  return `${results} ago`;
};

export const timeSinceIndo = (dateNow, date) => {
  const currentDate = dateNow || new Date();
  const seconds = Math.floor((currentDate - new Date(date)) / 1000);
  let results;
  if ((seconds / 31536000) > 1) {
    results = Math.floor(seconds / 31536000) + " Tahun";
  } else if ((seconds / 2592000) > 1) {
    results = Math.floor(seconds / 2592000) + " Bulan";
  } else if ((seconds / 86400) > 1) {
    results = Math.floor(seconds / 86400) + " Hari";
  } else if ((seconds / 3600) > 1) {
    results = Math.floor(seconds / 3600) + " Jam";
  } else if ((seconds / 60) > 1) {
    results = Math.floor(seconds / 60) + " Menit";
  } else {
    results = Math.floor(seconds) + " Detik";
  }

  return `${results}`;
};

export const waitingTimeColor = (number, label) => {
  const labelLower = String(label).toLowerCase();
  const numberInt = parseInt(number);
  let colorClass = "green";

  if (labelLower === "detik") {
    colorClass = "green";
  } else if (labelLower === "menit") {
    if (numberInt <= 5) {
      colorClass = "green";
    } else if (numberInt <= 13) {
      colorClass = "yellow";
    } else if (numberInt <= 17) {
      colorClass = "orange";
    } else {
      colorClass = "red";
    }
  } else {
    colorClass = "red";
  }
  return colorClass;
};

export const accumulateAllOrders = (allMenuProcess) => {
  const newAccumulate = {};
  if (allMenuProcess) {
    if (Array.isArray(allMenuProcess)) {
      if (allMenuProcess.length > 0) {
        allMenuProcess.forEach((selectedMenu) => {
          if (selectedMenu.menu) {
            if (Array.isArray(selectedMenu.menu)) {
              if (selectedMenu.menu.length > 0) {
                selectedMenu.menu.forEach((item) => {
                  if (!item.isComplete) {
                    if (!item.isVoid) {
                      const accumulate = newAccumulate[item.id];
                      newAccumulate[item.id] = {
                        title: item.name,
                        quantity: (accumulate ? (accumulate.quantity ? parseInt(accumulate.quantity) : 0) : 0) + parseInt(item.amount),
                        note: accumulate
                          ? (accumulate.note
                            ? [...accumulate.note, ...(item.note ? [item.note] : [])]
                            : item.note ? [item.note] : [])
                          : (item.note ? [item.note] : []),
                      };
                    }
                  }
                });
              }
            }
          }
        });
      }
    }
  }
  return newAccumulate;
};
