import React from "react";
import propTypes from "prop-types";

const Category = ({ onClick, className, label, icon, isActive }) => {
  const categoryClassName = ["category"];
  if (isActive) categoryClassName.push("active");
  categoryClassName.push(className);
  return (
    <button onClick={onClick} type="button" className={categoryClassName.join(" ")}>
      {
        icon &&
        <div className="category-icon">
          {icon}
        </div>
      }
      <div className="category-title">
        {label}
      </div>
    </button>
  );
};

Category.propTypes = {
  onClick: propTypes.func.isRequired,
  className: propTypes.string,
  label: propTypes.string.isRequired,
  icon: propTypes.string,
  isActive: propTypes.bool,
};

Category.defaultProps = {
  className: undefined,
  icon: "",
  isActive: false,
};

export default Category;
