import React, { useEffect, useReducer, useState } from "react";
import { Main } from "components/templates";
import { History, Nota } from "components/molecules";
import { historyList, historyDrinkList, historyFoodList } from "services/backend";
import Modal from "components/organisms/Modal";
import { Button, DrinkIcon, Flex, FoodIcon } from "components/atoms";
import io from "socket.io-client";
import { timeSinceIndo } from "utils/helper";
const socketServer = process.env.REACT_APP_SOCKET;
const socket = io(socketServer, { secure: true });
const intervalScheduler = 1;

const NotaHistory = () => {
  let dateNowInterval = null;
  const [type, setType] = useState("");
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [dateNow, setDateNow] = useState(new Date());
  const [filterParams, setFilterParams] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      page: 1,
      limit: 90,
    }
  );
  const [history, setHistory] = useState([]);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [notaDetail, setNotaDetail] = useState({
    menu: [],
  });

  useEffect(() => {
    turnOnSocket();
    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, []);

  const turnOnSocket = () => {
    socket.on("connect", () => {
      setIsConnected(true);
      if (!dateNowInterval) {
        schedulerRefreshDateNow();
      }
    });
  };

  useEffect(() => {
    getData();
  }, [filterParams]);

  const schedulerRefreshDateNow = () => {
    dateNowInterval = setInterval(() => {
      setDateNow(new Date());
    }, intervalScheduler * 1000);
  };

  const getData = async () => {
    if (type) {
      let data = [];
      if (type === "drink") {
        data = await historyDrinkList(filterParams);
      }
      if (type === "food") {
        data = await historyFoodList(filterParams);
      }
      const results = data.data.results.data;
      console.log(results);
      setHistory(results);
    }
  };

  const handleFilter = (event) => {
    const { value, name } = event.target;
    setFilterParams({ [name]: value });
  };

  const openModalDetail = (notaId) => {
    const notaDetail = history.find(({ id }) => id === notaId);
    setShowModalDetail(true);
    setNotaDetail(notaDetail);
    console.log(notaDetail);
  };

  const getDataDrink = async () => {
    setType("drink");
    try {
      const data = await historyDrinkList(filterParams);
      const results = data.data.results.data;

      setHistory(results);
    } catch (error) {

    }
  };

  const getDataFood = async () => {
    setType("food");
    try {
      const data = await historyFoodList(filterParams);
      const results = data.data.results.data;

      setHistory(results);
    } catch (error) {

    }
  };

  return (
    <Main isLive={isConnected}>
      <div className="history px-4">
        <div className="history-header">
          <div className="history-title">SEMUA TRANSAKSI</div>
        </div>
        {
          type
            ? (
              <div className="history-body">
                <div className="history-filter mb-7">
                  <input
                    onChange={handleFilter}
                    className="filter"
                    type="date"
                    name="date"
                  />
                  <input
                    onChange={handleFilter}
                    className="filter"
                    type="search"
                    name="cn"
                    placeholder="Nama Customer"
                  />
                  <input
                    onChange={handleFilter}
                    className="filter"
                    type="search"
                    name="tableNo"
                    placeholder="Meja"
                  />
                </div>
                <div className="width-full">
                  {
                    history.length > 0
                      ? <div className="row">
                        {
                          history.map((item, index) => {
                            const createdAt = item.createdAt;
                            const time = String(timeSinceIndo(dateNow, createdAt)).split(" ");
                            const timeNumber = time[0];
                            const timeLabel = time[1];
                            return (
                              <div className="col-12 col-lg-6 mb-5" key={index}>
                                <Nota
                                  type="history"
                                  tableNumber={item.tableNo}
                                  customerName={item.customerName}
                                  orders={item.menu}
                                  timeLabel={timeLabel}
                                  timeNumber={timeNumber}
                                  transactionCode={item.id}
                                  onDetail={() => {
                                    openModalDetail(item.id);
                                  }}
                                />
                              </div>
                            );
                          })
                        }
                      </div>
                      : <h1 className="mt-10 text-center">TIDAK ADA DATA</h1>
                  }
                </div>
              </div>
            )
            : (
              <>
                <h1 className="text-center mt-14">PILIH TIPE TRANSAKSI</h1>
                <div className="text-center mt-3">
                  <button
                    type="button"
                    className="btn-transaction mr-4"
                    onClick={() => getDataDrink()}
                  >
                    {/* <DrinkIcon size={1.4} /> */}
                    DRINK ( BAR )
                  </button>
                  <button
                    type="button"
                    className="btn-transaction"
                    onClick={() => getDataFood()}
                  >
                    {/* <FoodIcon size="2" /> */}
                    FOOD ( KITCHEN )
                  </button>
                </div>
              </>
            )
        }
      </div>

      <Modal show={showModalDetail}>
        <div className="nota-detail">
          <div className="nota-detail-header">
            <div className="display-flex align-y-center">
              <h1 className="nota-detail-header-title">{notaDetail.tableNo}</h1>
              <div className="ml-2">
                <div className="text-weight-medium">{new Date(notaDetail.createdAt).toLocaleString("id-ID")}</div>
                <div>{notaDetail.customerName}</div>
              </div>
            </div>
          </div>
          <div className="nota-detail-content">
            {notaDetail.menu.map(({ name, amount }, index) => (
              <div key={index} className="nota-list">
                <div className="nota-list-menu">{name}</div>
                <div className="nota-list-qty">{amount}x</div>
              </div>
            ))}
          </div>
        </div>
        <Flex className="mt-3" justify="end">
          <Button color="red" onClick={() => {
            setShowModalDetail(false);
          }}
          >
            TUTUP
          </Button>
        </Flex>
      </Modal>
    </Main>
  );
};

export default NotaHistory;
