import Error from "pages/Error";
import React from "react";
import { Navigate } from "react-router-dom";

const UnAuthenticatedRoute = ({
  redirectPath = "/",
  children,
}) => {
  const userToken = localStorage.getItem("userToken");

  if (userToken) {
    return <Navigate to={redirectPath} replace />;
  }
  return children;
};

export default UnAuthenticatedRoute;
