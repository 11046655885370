import React from "react";

const DrinkIcon = (props) => {
  const { size } = props;
  const svgSize = (parseInt(size) < 1 ? 1 : parseInt(size)) || 0;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 320 512"
      width={10 * svgSize}
      height={16 * svgSize}
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    ><path d="M64 0C47.4 0 33.5 12.8 32.1 29.3l-14 168.4c-6 72 42.5 135.2 109.9 150.6V448H80c-17.7 0-32 14.3-32 32s14.3 32 32 32h80 80c17.7 0 32-14.3 32-32s-14.3-32-32-32H192V348.4c67.4-15.4 115.9-78.6 109.9-150.6l-14-168.4C286.5 12.8 272.6 0 256 0H64zM88.1 128l5.3-64H226.6l5.3 64H88.1z"/></svg>
  );
};

export default DrinkIcon;
