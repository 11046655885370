import React from "react";
import propTypes from "prop-types";
import { Button } from "components/atoms";

const Modal = ({
  children,
  className,
  onClose,
  show,
  size,
  radius,
  footerButton,
}) => {
  const modalClassName = ["modal-box"];

  if (size) modalClassName.push(`modal-box-${size}`);
  if (radius) modalClassName.push(`modal-box-radius-${radius}`);
  if (className) modalClassName.push(className);

  const handleCloseModal = () => {
    if (onClose) onClose();
  };

  if (!show) return null;
  return (
    <div className="modal">
      <div className="modal-wrapper">
        <div className="modal-background" onClick={handleCloseModal}></div>
        <div className={modalClassName.join(" ")}>
          {
            // onClose && (
            //   <Button onClick={handleCloseModal} className="modal-close" variant="plain">
            //     <i className="resitdc icon-x" />
            //   </Button>
            // )
          }
          {children}
        </div>
      </div>
    </div>
  );
};

Modal.propTypes = {
  children: propTypes.node.isRequired,
  className: propTypes.node,
  onClose: propTypes.func,
  show: propTypes.bool.isRequired,
  size: propTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  radius: propTypes.oneOf(["xs", "sm", "md", "lg", "xl"]),
  footerButton: propTypes.object,
};

Modal.defaultProps = {
  show: false,
  size: "md",
  radius: "xs",
  footerButton: {
    confirm: {
      text: "Oke",
      onClick: () => {},
    },
  },
};

export default Modal;
