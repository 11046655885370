import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { Button, Col, Flex, Image, Row } from "components/atoms";
import { FormInput, Toast } from "components/molecules";
import { login } from "services/backend";
import RooftopKitchen from "assets/images/rooftop-kitchen-white.png";
import RooftopLogo from "assets/images/rooftop.png";

const Login = () => {
  // #region - Initial states
  const [isLoading, setIsLoading] = useState(false);
  // #endregion - Initial states

  const navigate = useNavigate();

  // #region - Handlers
  const handleSubmit = async (values, { resetForm }) => {
    setIsLoading(true);

    try {
      const { data } = await login(values);
      const { success, accessToken } = data;

      if (success) {
        localStorage.setItem("userToken", accessToken);
        setTimeout(() => {
          window.location.href = window.location.origin;
        }, 100);
      }
    } catch (error) {
      resetForm();
    } finally {
      setIsLoading(false);
    }
  };
  // #endregion - Handlers

  // Validation Form
  const validationSchema = Yup.object().shape({
    user: Yup.string()
      .required("Tidak boleh kosong"),
    password: Yup.string()
      .required("Tidak boleh kosong"),
  });

  return (
    <>
      {/* <div className="bacod-toast">
        <div className="bacod-toast-container">
          <Toast
            message="Testing Toast 1"
            isClose={true}
          />
          <Toast
            color="red"
            message="Testing Toast 2"
          />
          <Toast
            color="green"
            message="Testing Toast 3"
          />
          <Toast
            color="yellow"
            message="Testing Toast 3"
          />
        </div>
      </div> */}
      <div className="auth-page">
        <div className="auth-wrapper">
          <div className="auth-box">
            <div className="auth-box-wrapper">
              <div className="auth-box-left">
                <div className="auth-box-form">
                  <div className="auth-logo mb-2">
                    <img src={RooftopLogo} alt="ROOFTOP LOGO" />
                  </div>
                  <Formik
                    initialValues={{
                      user: "",
                      password: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={handleSubmit}
                  >
                    {({ errors, touched }) => (
                      <Form>
                        <div className="mb-2">
                          <FormInput
                            formik="true"
                            label="Email / Username"
                            name="user"
                            placeholder="Email / Username"
                            errormessage={(errors.user && touched.user) && errors.user}
                          />
                        </div>
                        <div className="mb-4">
                          <FormInput
                            formik="true"
                            type="password"
                            label="Password"
                            name="password"
                            placeholder="Password"
                            errormessage={(errors.password && touched.password) && errors.password}
                          />
                        </div>
                        <Flex justify="end">
                          <Button
                            className="width-full width-lg-auto"
                            color="blue"
                            radius="sm"
                            loading={isLoading}
                            type="submit"
                          >
                            MASUK
                          </Button>
                        </Flex>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
              <div className="auth-box-right">
                <img src={RooftopKitchen} alt="ROOFTOP KITCHEN" width="60%" />
                <h3 className="mt-4">APLIKASI KITCHEN DAN BAR</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
