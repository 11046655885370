import React from "react";

const SidebarItem = (props) => {
  const { title, icon } = props;

  const onClickHandler = () => {
    if (props.onClick) props.onClick();
  };

  return (
    <li className="sidebar-item" onClick={onClickHandler}>
      <div className="sidebar-item-wrapper">
        {
          icon &&
          <div className="sidebar-item-icon">
            {icon}
          </div>
        }
        {
          title &&
          <div className="sidebar-item-title">{title}</div>
        }
      </div>
    </li>
  );
};

export default SidebarItem;
