import React from "react";
import propTypes from "prop-types";
import { Field } from "formik";

const FormInput = (props) => {
  const { onChange, label, errormessage, formik } = props;
  let Input = "input";

  if (formik === "true") Input = Field;

  return (
    <div className="position-relative">
      {label && (
        <div className="form-group-label">
          {label}
        </div>
      )}
      <Input
        {...props}
        {...(onChange ? { onChange: onChange } : {})}
        className="form-input form-input-small"
      />
      {errormessage && (
        <div className="form-input-error-icon">
          <i className="resitdc icon-alert-triangle" />
        </div>
      )}
    </div>
  );
};

FormInput.propTypes = {
  onChange: propTypes.func,
  label: propTypes.string,
  errormessage: propTypes.string,
  formik: propTypes.string,
};

FormInput.defaultProps = {
  type: "text",
  label: undefined,
  formik: "false",
};

export default FormInput;
