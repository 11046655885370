import React from "react";
import { AccumulateItem } from "components/molecules";

const AccumulateSidebar = (props) => {
  const { list } = props;
  return (
    <div className="nota-live-right">
      <div className="nota-sidebar-wrapper">
        <h2 className="mb-1">AKUMULASI</h2>
        {
          list &&
          (
            Object.keys(list).length > 0 &&
            <ul className="nota-akumulasi">
              {
                Object.keys(list).map((key, index) => (
                  <AccumulateItem
                    key={index}
                    title={list[key].title}
                    quantity={list[key].quantity}
                    note={list[key].note}
                  />
                ))
              }
            </ul>
          )
        }
      </div>
    </div>
  );
};

export default AccumulateSidebar;
