import React, { useState, useEffect } from "react";
import { DrinkIcon, FoodIcon, LogoRooftop } from "components/atoms";
import { KitchenType } from "components/molecules";
import jwtDecode from "jwt-decode";
import defaultAvatar from "assets/images/avatar.png";

const Page = () => {
  const [userData, setUserData] = useState({});

  useEffect(() => {
    if (Object.keys(userData).length === 0) {
      const userToken = localStorage.getItem("userToken");
      const userDataTemp = jwtDecode(userToken);
      setUserData(userDataTemp);
    }
  }, []);

  const logout = () => {
    localStorage.removeItem("userToken");
    window.location.href = "/login";
  };

  return (
    <div className="d-flex align-x-center align-y-center height-full width-full flex-direction-column">
      <div className="brand mb-3">
        <div className="brand-logo">
          <LogoRooftop size="6" />
        </div>
        <div className="brand-info mb-3">
          <h1>ROOFTOP COFFEE</h1>
        </div>
      </div>
      <div className="mt-4 d-flex align-x-center width-full">
        {/* href="/food" */}
        <KitchenType
          icon={<FoodIcon size="4" />}
          title="Food"
          className="food"
          onClick={() => {
            window.location.href = "/food";
          }}
        />
        {/* href="/drink" */}
        <KitchenType
          icon={<DrinkIcon size="4" />}
          title="Drink"
          className="drink"
          onClick={() => {
            window.location.href = "/drink";
          }}
        />
      </div>
      <div className="mt-8 d-flex align-x-center width-full">
        <div className="user-account">
          <div className="user-account-avatar">
            <img
              src={userData.avatar || defaultAvatar} alt="USER AVATAR"
              className="user-account-avatar-image"
            />
          </div>
          <div className="user-account-info">
            <div className="user-account-name">
              {userData.name || <i>ANONYMOUS</i>}
            </div>
            {
              userData.email &&
              <div className="user-account-email">
                {userData.email}
              </div>
            }
          </div>
          <div className="user-account-logout" onClick={logout}>
            <span className="resitdc icon-log-out"></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Page;
