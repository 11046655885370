import React from "react";

const AccumulateItem = (props) => {
  const { title, quantity, note } = props;
  return (
    <li className="akumulasi-item">
      <div className="akumulasi-menu">
        <div className="akumulasi-menu-title">{title}</div>
        <div className="akumulasi-menu-quantity">{quantity}</div>
      </div>
      {
        /*
        note &&
        (
          note.length > 0 &&
          <div className="akumulasi-customer-note">
            {
              note.map((item, index) => (
                <p key={index}>{item}</p>
              ))
            }
          </div>
        )
        */
      }
    </li>
  );
};

export default AccumulateItem;
