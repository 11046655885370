import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
  useSearchParams
} from "react-router-dom";

// #region - IMPORT PAGES
import { AuthenticatedRoute, UnAuthenticatedRoute } from "components/atoms";

import Error from "pages/Error";
import Home from "pages/Home";
import Login from "pages/Login";
import Drink from "pages/Drink";
import Food from "pages/Food";
import Nota from "pages/Nota";
import Sales from "pages/Sales";
// #endregion - IMPORT PAGES

const RouteList = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Error />} />
        <Route path="/" element={
          // <AuthenticatedRoute>
          <Home />
          // </AuthenticatedRoute>
        } />
        <Route path="/drink" element={
          <AuthenticatedRoute>
            <Drink />
          </AuthenticatedRoute>
        } />
        <Route path="/food" element={
          <AuthenticatedRoute>
            <Food />
          </AuthenticatedRoute>
        } />
        <Route path="/nota" element={
          <AuthenticatedRoute>
            <Nota />
          </AuthenticatedRoute>
        } />
        <Route path="/sales" element={
          <AuthenticatedRoute>
            <Sales />
          </AuthenticatedRoute>
        } />
        <Route path="/login" element={
          <UnAuthenticatedRoute>
            <Login />
          </UnAuthenticatedRoute>
        } />
      </Routes>
    </BrowserRouter>
  );
};

export default RouteList;
