import React, { useState } from "react";
import propTypes from "prop-types";
import useLongPress from "hooks/LongPress";

const Nota = (props) => {
  const {
    type,
    tableNumber,
    customerName,
    orders,
    timeLabel,
    timeNumber,
    timeColor,
    transactionCode,
    onDetail,
    transactionId,
    isOpenMenu,
  } = props;

  const onClickHandler = () => {
    if (props.onClick) props.onClick();
  };

  const onDetailHandler = () => {
    if (props.onDetail) props.onDetail();
  };

  const onClickMenuHandler = (selectorData) => {
    if (props.onClickMenu) props.onClickMenu(selectorData);
  };

  const onClickVoidHandler = (selectorData) => {
    if (props.onClickVoid) props.onClickVoid(selectorData);
  };

  return (
    <div className="nota">
      <div className="nota-header" onClick={onClickHandler}>
        {/* <div className={`nota-table ${String(tableNumber).length > 2 ? "TEST" : ""}`}>
          {tableNumber}
        </div> */}
        <div className="nota-customer-info">
          <div className="nota-customer-table">
            {tableNumber}
          </div>
          {/* {transactionCode && type === "history" && (
            <div className="nota-transaction-code">{transactionCode}</div>
          )} */}
          <div className="nota-customer-name">
            {customerName}
          </div>
        </div>
        {
          timeNumber && !transactionCode && (
            <div
              className={`nota-waiting-time ${timeColor || ""}`}
              label={timeLabel}
            >
              {timeNumber}
            </div>
          )}
      </div>
      <div className="nota-body" onClick={onClickHandler}>
        {
          orders &&
          (
            orders.length > 0
              ? (
                <ul className="nota-orders">
                  {
                    orders.map((item, index) => (
                      <li
                        className="order"
                        key={index}
                        onClick={() => onClickMenuHandler({
                          transactionId,
                          menuId: item.id,
                          detailTransactionId: item.detailTransactionId,
                        })}
                      >
                        {item.isVoid && <div className="order-void"></div>}
                        {!item.isVoid && item.isComplete && type !== "history" && <div className="order-complete"></div>}
                        {/* <div className={"order-menu" + `${item.isPackage ? " order-menu-package" : ""}`}> */}
                        <div className="order-menu">
                          <span className={`order-menu-name ${item.isPackage ? " order-menu-package" : ""}`}>
                            {item.name}
                          </span>
                          <span className="order-menu-amount text-weight-medium">{item.amount}x</span>
                        </div>
                        {
                          item.note &&
                          <div className="order-customer-note">{item.note}</div>
                        }
                        {
                          isOpenMenu &&
                          <div className="order-sidebar-menu">
                            <button
                              type="button"
                              className="order-menu-cancel"
                              onClick={() => onClickVoidHandler({ transactionId, menuId: item.id })}
                            >
                              <i className="resitdc icon-x"></i>
                            </button>
                          </div>
                        }
                      </li>
                    ))
                  }
                </ul>
              )
              : <h2 className="text-center mt-3">NO MENU ORDERS</h2>
          )
        }
      </div>
      {onDetail && type !== "history" && (
        <button
          type="button"
          className="btn-detail-nota"
          onClick={onDetailHandler}
        >
          <i className="resitdc icon-maximize-2"></i>
        </button>
      )}
      {onDetail && type === "history" && (
        <button
          type="button"
          onClick={onDetailHandler}
          className="btn-see-more-detail">
            See More Detail
        </button>
      )}
    </div>
  );
};

Nota.propTypes = {
  tableNumber: propTypes.string.isRequired,
  customerName: propTypes.string.isRequired,
};

export default Nota;
