import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";

const Navbar = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const branch = searchParams.get("branch");
  const { total } = useSelector((state) => state.cartReducer);

  return (
    <div className="navbar">
      <div className="navbar-wrapper">
        <div className="navbar-title">
          Rooftop Coffee
        </div>
        <div className="navbar-cart">
          <button
            type="button"
            className="btn-cart"
            onClick={() => {
              navigate(`/cart?branch=${branch}`);
            }}
          >
            {total > 0 && (
              <span className="btn-cart-count">{total}</span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
