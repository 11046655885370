import React from "react";

const LogoRooftop = (props) => {
  const size = props.size ? parseInt(props.size) : 1;

  return (
    <svg width={size * (22.125)} height={size * (21.8125)} viewBox="0 0 708 698" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M336.192 10.1364C347.232 3.85214 360.768 3.85215 371.808 10.1364L642.381 164.145C653.628 170.547 660.573 182.492 660.573 195.432V502.568C660.573 515.508 653.628 527.453 642.381 533.855L371.808 687.864C360.768 694.148 347.232 694.148 336.192 687.864L65.6187 533.855C54.3723 527.453 47.427 515.508 47.427 502.568V195.432C47.427 182.492 54.3724 170.547 65.6187 164.145L336.192 10.1364Z" fill="#6E1008"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M354.14 513C354.14 513 489.28 390.813 489.28 317.231C489.28 243.65 428.776 184 354.14 184C279.504 184 219 243.65 219 317.231C219 390.813 354.14 513 354.14 513ZM354.83 409.678C407.76 409.678 450.669 367.375 450.669 315.192C450.669 263.009 407.76 220.707 354.83 220.707C301.899 220.707 258.991 263.009 258.991 315.192C258.991 367.375 301.899 409.678 354.83 409.678Z" fill="#E89538"/>
      <path d="M347.072 375.359C340.356 373.29 311.392 366.668 311.392 324.456C311.392 265.919 332.8 265.69 351.69 257.413C335.319 273.553 339.237 303.828 347.072 314.524C352.529 321.973 363.863 341.423 347.072 375.359Z" fill="#E89632"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M358.873 257.657L358.855 257.652C342.064 291.587 353.398 311.037 358.855 318.487C366.69 329.182 370.609 359.457 354.237 375.597C357.084 374.35 360.041 373.34 363.01 372.325C379.749 366.607 396.889 360.752 396.889 311.544C396.889 269.369 365.626 259.738 358.873 257.657ZM385.77 325.028C385.721 323.925 384.786 323.067 383.682 323.11C382.578 323.154 381.722 324.083 381.77 325.186C382.217 335.339 376.964 345.857 374.269 349.813C373.649 350.724 373.888 351.968 374.803 352.591C375.718 353.214 376.963 352.982 377.583 352.071C380.512 347.771 386.269 336.397 385.77 325.028Z" fill="#E89632"/>
    </svg>
  );
};

export default LogoRooftop;
