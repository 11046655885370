import React from "react";
import { AccumulateSidebar } from "components/organisms";

const NotaLayout = (props) => {
  const { children, list } = props;

  return (
    <>
      <div className="nota-live-left">
        {children}
      </div>
      <AccumulateSidebar list={list} />
    </>
  );
};

export default NotaLayout;
