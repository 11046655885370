/* eslint-disable consistent-return */
function errorResponseHandler (error) {
  if (error) {
    let message = "SOMETHING WRONG";

    if (error.response) {
      if (error.response.status === 500) {
        message = "Something went wrong";
      } else {
        message = error.response.message;
      }
      if (error.response.data) {
        message = error.response.data.message;
      }
    }
    return Promise.reject(error);
  }
}

export default errorResponseHandler;
