import React from "react";
import propTypes from "prop-types";

const BottomSheet = ({ children, className, onClose, show, size }) => {
  const bottomSheetClassName = ["bottom-sheet-wrapper"];

  if (size) bottomSheetClassName.push(`bottom-sheet-wrapper-${size}`);
  if (className) bottomSheetClassName.push(className);

  if (!show) return null;
  return (
    <div className="bottom-sheet">
      <div className={bottomSheetClassName.join(" ")}>
        <button onClick={onClose} className="bottom-sheet-close" type="button">
          X
        </button>
        {children}
      </div>
    </div>
  );
};

BottomSheet.propTypes = {
  children: propTypes.node.isRequired,
  className: propTypes.string,
  show: propTypes.bool,
  onClose: propTypes.func.isRequired,
  size: propTypes.oneOf(["sm", "md"]),
};

BottomSheet.defaultProps = {
  className: undefined,
  show: false,
  size: "md",
};

export default BottomSheet;
