import React from "react";
import { useNavigate } from "react-router-dom";

const KitchenType = (props) => {
  const navigate = useNavigate();
  const { icon, title, href } = props;
  const className = ["kitchen-type-icon"];
  if (props.className) className.push(props.className);

  const onClickHandler = (e) => {
    if (href) {
      navigate(href);
    } else if (props.onClick) {
      props.onClick(e);
    }
  };

  return (
    <div
      className="kitchen-type"
      onClick={onClickHandler}
    >
      <div className="kitchen-type-wrapper">
        {
          icon &&
          <div className={className.join(" ")}>
            {icon}
          </div>
        }
        {
          title &&
          <div className="kitchen-type-title">
            {title}
          </div>
        }
      </div>
    </div>
  );
};

export default KitchenType;
