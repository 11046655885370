import axios from "services/axios";

// #region - Nota
export const foodList = (params) => {
  return axios.get("nota/food", {
    params,
  });
};

export const foodProcessList = (page, limit) => {
  return axios.get("nota/process/food", {
    params: {
      limit: limit,
      page: page,
    },
  });
};

export const drinkList = (params) => {
  return axios.get("nota/drink", {
    params,
  });
};

export const drinkProcessList = (page, limit) => {
  return axios.get("nota/process/drink", {
    params: {
      limit: limit,
      page: page,
    },
  });
};

export const notaProcess = (formData) => {
  return axios.post("nota/process", formData);
};

export const notaComplete = (formData) => {
  return axios.patch("nota/complete", formData);
};

export const notaCompleteMenu = (transactionId, menuId, formData) => {
  return axios.patch(`nota/completeMenu/${transactionId}/${menuId}`, formData);
};

export const notaCompleteMenuV2 = (transactionId, detailTransactionId, formData) => {
  return axios.patch(`nota/completeMenuV2/${transactionId}/${detailTransactionId}`, formData);
};

export const cancelMenu = (transactionId, formData) => {
  return axios.patch(`transactions/menu/void/${transactionId}`, formData);
};

export const historyFoodList = (params) => {
  return axios.get("nota/history/food", {
    params,
  });
};

export const historyDrinkList = (params) => {
  return axios.get("nota/history/drink", {
    params,
  });
};
// #endregion - Nota

// #region - SALES
export const totalSales = (date, type, kitchen) => {
  return axios.get("sales/total", {
    params: {
      date: date,
      type: type,
      kitchen: kitchen,
    },
  });
};
// #endregion - SALES

// #region - Auth
export const login = (formData) => {
  return axios.post("/auth/login", formData);
};
// #endregion - Auth
