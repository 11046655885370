import React from "react";
import propTypes from "prop-types";

function FormCheckbox ({ onChange, name, type, label, value }) {
  return (
    <>
      <label className="display-flex pr-3">
        <input
          onChange={onChange}
          type={type}
          name={name}
          value={value}
        />
        <small className="text-center">{label}</small>
      </label>
    </>
  );
}

FormCheckbox.defaultProps = {
  type: "checkbox",
  value: "",
};

FormCheckbox.propTypes = {
  onChange: propTypes.func,
  name: propTypes.string.isRequired,
  type: propTypes.oneOf(["radio", "checkbox"]).isRequired,
  label: propTypes.string.isRequired,
  value: propTypes
    .oneOfType([
      propTypes.string,
      propTypes.number,
      propTypes.bool])
    .isRequired,
  checked: propTypes.bool,
};

export default FormCheckbox;
