import React from "react";
import propTypes from "prop-types";
import { useNavigate } from "react-router-dom";
import { SidebarItem } from "components/molecules";
import { logout } from "utils/helper";
const menu = [
  {
    title: "Home",
    icon: <i className="resitdc icon-home"></i>,
    link: "/",
  },
  {
    title: "Drink",
    icon: <i className="resitdc icon-clipboard"></i>,
    link: "/drink",
  },
  {
    title: "Food",
    icon: <i className="resitdc icon-clipboard"></i>,
    link: "/food",
  },
  {
    title: "History",
    icon: <i className="resitdc icon-map"></i>,
    link: "/nota",
  },
  {
    title: "Sales",
    icon: <i className="resitdc icon-bar-chart"></i>,
    link: "/sales",
  },
  // {
  //   title: "History",
  //   icon: <i className="resitdc icon-map"></i>,
  //   link: "/history",
  // },
];

const Main = (props) => {
  const { children, isLive } = props;
  const navigate = useNavigate();

  return (
    <div className="main">
      <div className="main-sidebar">
        <ul className="sidebar-primary">
          {
            menu.map((item, index) => (
              <SidebarItem
                key={index}
                title={item.title}
                icon={item.icon}
                onClick={() => {
                  if (item.link === "/login") {
                    logout(() => {
                      return navigate(item.link);
                    });
                  } else if (["DRINK", "FOOD"].includes(String(item.title).toUpperCase())) {
                    window.location.href = item.link;
                  }
                  return navigate(item.link);
                }}
              />
            ))
          }
        </ul>
        <div className="sidebar-footer">
          <div className={`live-status${!isLive ? " off" : ""}`}>
            { isLive ? "LIVE" : "OFF" }
          </div>
        </div>
      </div>
      <div className="main-body">
        {children}
      </div>
    </div>
  );
};

Main.propTypes = {
  children: propTypes.node.isRequired,
};

export default Main;
