import React, { useState, useEffect } from "react";
import { Nota } from "components/molecules";
import { Main, NotaLayout } from "components/templates";
import { Button, Flex } from "components/atoms";
import io from "socket.io-client";
import {
  drinkList,
  drinkProcessList,
  notaProcess,
  notaComplete,
  notaCompleteMenu,
  notaCompleteMenuV2,
  cancelMenu
} from "services/backend";
import useLongPress from "hooks/LongPress";
import { Modal } from "components/organisms";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { timeSinceIndo, waitingTimeColor, accumulateAllOrders } from "utils/helper";
const socketServer = process.env.REACT_APP_SOCKET;
const socket = io(socketServer, { secure: true });
dayjs.extend(utc);
const intervalScheduler = 1;

const DrinkPage = () => {
  let dateNowInterval = null;
  let disconnectCount = 0;
  const [listAccumulate, setListAccumulate] = useState({});
  const [listQueue, setListQueue] = useState([]);
  const [listProcess, setListProcess] = useState([]);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [isConnected, setIsConnected] = useState(socket.connected);
  const [isLoadingCompleteNota, setIsLoadingCompleteNota] = useState(false);
  const [dateNow, setDateNow] = useState(new Date());
  const [notaIdProcessFromSocket, setNotaIdProcessFromSocket] = useState("");
  const [notaIdCompleteFromSocket, setNotaIdCompleteFromSocket] = useState([]);
  const [detailNota, setDetailNota] = useState({});
  const [isFetchingData, setIsFetchingData] = useState(true);
  const [isDeleteMode, setIsDeleteMode] = useState(false);

  useEffect(() => {
    getDrinkNota();
    turnOnSocket();
    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, []);

  useEffect(() => {
    if (notaIdProcessFromSocket) {
      onClickQueue(notaIdProcessFromSocket, true);
    }
  }, [notaIdProcessFromSocket]);

  useEffect(() => {
    if (notaIdCompleteFromSocket.length > 0) {
      trigerCompleteNotaViaSocket(notaIdCompleteFromSocket);
    }
  }, [notaIdCompleteFromSocket]);

  const turnOnSocket = () => {
    socket.on("connect", () => {
      setIsConnected(true);
      if (disconnectCount > 0) {
        getDrinkNota(true);
      }
      if (!dateNowInterval) {
        schedulerRefreshDateNow();
      }
    });

    socket.on("listenNotaDrink", (response) => {
      try {
        const nota = JSON.parse(response);
        setListQueue((list) => [...list, nota]);
      } catch (error) {
        const nota = response;
        setListQueue((list) => [...list, nota]);
      }
    });

    socket.on("listenProcessNotaDrink", (response) => {
      const notaId = response;
      if (notaId) {
        setNotaIdProcessFromSocket(response);
        // GATAU KENAPA INI KALO DIPANGGIL LEWAT CALLBACK SOCKET
        // STATE ARRAY YANG ADA DIDALEMNYA JADI KOSONG
        // MAKANYA MANGGIL LEWAT USE STATE DEPENDENCIES
        // onClickQueue(notaId, true);
      }
    });

    socket.on("listenCompleteNotaDrink", (response) => {
      const notaId = response;
      if (notaId) {
        if (Array.isArray(notaId)) {
          setNotaIdCompleteFromSocket(notaId);
        }
        // GATAU KENAPA INI KALO DIPANGGIL LEWAT CALLBACK SOCKET
        // STATE ARRAY YANG ADA DIDALEMNYA JADI KOSONG
        // MAKANYA MANGGIL LEWAT USE STATE DEPENDENCIES
        // onClickQueue(notaId, true);
      }
    });

    socket.on("listenVoidTransaction", (response) => {
      const nota = response;

      if (nota) {
        const notaId = nota.id;
        setListQueue((list) => list.filter((o) => o.id !== notaId));
      }
    });

    socket.on("listenVoidMenuDrink", (response) => {
      const nota = response;
      if (nota) {
        const transactionId = nota.id;
        const menuId = nota.menuId;
        setListProcess((list) => {
          const listProcessTemp = list;
          const selectedIndexProcess = listProcessTemp.findIndex(o => o.id === transactionId);
          if (selectedIndexProcess >= 0) {
            const selectedProcess = listProcessTemp[selectedIndexProcess];
            const listMenu = selectedProcess.menu || [];
            const selectedIndexMenu = listMenu.findIndex(o => o.id === menuId);
            const selectedMenu = listMenu[selectedIndexMenu];
            selectedMenu.isVoid = true;
            accumulateAll(listProcessTemp);
            return [...[], ...listProcessTemp];
          } else {
            return list;
          }
        });

        setListQueue((list) => {
          const listQueueTemp = list;
          const selectedIndexQueue = listQueueTemp.findIndex(o => o.id === transactionId);
          if (selectedIndexQueue >= 0) {
            const selectedQueue = listQueueTemp[selectedIndexQueue];
            const listMenu = selectedQueue.menu || [];
            const selectedIndexMenu = listMenu.findIndex(o => o.id === menuId);
            const selectedMenu = listMenu[selectedIndexMenu];
            selectedMenu.isVoid = true;
            accumulateAll(listQueueTemp);
            return [...[], ...listQueueTemp];
          } else {
            return list;
          }
        });
      }
    });

    socket.on("listenRefreshDrink", (response) => {
      const jsonData = response;
      if (jsonData) {
        getDrinkNota(true);
      }
    });

    socket.on("disconnect", () => {
      ++disconnectCount;
      setIsConnected(false);
    });
  };

  const getDrinkNota = async (afterDisconnected) => {
    if (!afterDisconnected) {
      setIsFetchingData(true);
    }
    try {
      const params = {
        limit: 1500,
        page: 1,
        filterCompleted: true,
      };
      const queue = drinkList(params);
      const process = drinkProcessList(1, 1500);
      const fetchData = await Promise.all([queue, process]);
      const responseQueue = fetchData[0].data.results.data;
      const responseProcess = fetchData[1].data.results.data;
      if (responseProcess) {
        if (responseProcess.length > 0) {
          accumulateAll(responseProcess);
        }
      }
      setListQueue(responseQueue);
      setListProcess(responseProcess);
      if (!dateNowInterval) {
        schedulerRefreshDateNow();
      }
    } catch (error) {
      alert("ERROR GET DATA, TRY TO RE-OPEN THE APP");
    } finally {
      if (!afterDisconnected) {
        setIsFetchingData(false);
      }
    }
  };

  const openModalDetail = (notaId, status) => {
    if (status === "queue") {
      setDetailNota(listQueue.find(o => o.id === notaId) || {});
    } else {
      setDetailNota(listProcess.find(o => o.id === notaId) || {});
    }
    setShowModalDetail(true);
  };

  const onClickQueue = (notaId, fromSocket) => {
    if (isLoadingCompleteNota) {
      modalWait();
    } else {
      const selectedQueue = listQueue.find(o => o.id === notaId);
      if (selectedQueue) {
        selectedQueue.isCompleted = false;
        selectedQueue.completedAt = null;
        accumulateOrders(selectedQueue.menu);
        setListProcess((list) => [...list, selectedQueue]);
        setListQueue((list) => list.filter((o) => o.id !== notaId));
        if (!fromSocket) processNota(notaId);
      }
    }
    if (fromSocket) {
      setNotaIdProcessFromSocket("");
    }
  };

  const onClickProcess = (notaId) => {
    if (isLoadingCompleteNota) {
      modalWait();
    } else {
      const selectedProcess = listProcess.findIndex(o => o.id === notaId);
      if (selectedProcess !== null) {
        const listProcessTemp = listProcess;
        listProcessTemp[selectedProcess].isComplete = !listProcessTemp[selectedProcess].isComplete;
        // SUPAYA RENDER ULANG
        setListProcess([...[], ...listProcessTemp]);
      }
    }
  };

  const accumulateOrders = (orderMenu) => {
    const newAccumulate = {};
    if (orderMenu) {
      if (orderMenu.length > 0) {
        orderMenu.forEach((item) => {
          const accumulate = listAccumulate[item.id];
          if (accumulate) {
            const listAccumulateTemp = listAccumulate;
            listAccumulateTemp[item.id] = {
              title: accumulate.title,
              quantity: parseInt(accumulate.quantity) + parseInt(item.amount),
              note: accumulate.note
                ? [...accumulate.note, ...(item.note ? [item.note] : [])]
                : item.note ? [item.note] : [],
            };
            setListAccumulate(listAccumulateTemp);
          } else {
            newAccumulate[item.id] = {
              title: item.name,
              quantity: parseInt(item.amount),
              note: item.note ? [item.note] : null,
            };
          }
        });
      }
    }
    setListAccumulate((list) => {
      return { ...list, ...newAccumulate };
    });
  };

  const processNota = async (transactionId) => {
    const formData = {
      transactionId: transactionId,
      type: "bar",
    };

    try {
      const response = await notaProcess(formData);
    } catch (error) {
      if (error.response.data.message) {
        alert(error.response.data.message);
      } else {
        alert("SOMETHING ERROR");
      }
    }
  };

  const schedulerRefreshDateNow = () => {
    dateNowInterval = setInterval(() => {
      setDateNow(new Date());
    }, intervalScheduler * 1000);
  };

  const setNotaComplete = async (notaId, callback) => {
    const formData = {
      transactionId: notaId,
      type: "bar",
    };
    setIsLoadingCompleteNota(true);
    try {
      const response = await notaComplete(formData);
      if (callback) callback();
    } catch (error) {
      if (error.response.data.message) {
        alert(error.response.data.message);
      } else {
        alert("SOMETHING ERROR");
      }
    } finally {
      setIsLoadingCompleteNota(false);
    }
  };

  const completeSelectedNota = () => {
    const selectedProcess = listProcess.filter((item) => item.isComplete === true);
    const unselectedProcess = listProcess.filter((item) => !item.isComplete);

    setNotaComplete(selectedProcess.map((item) => item.id), () => {
      setListProcess(() => [...[], ...unselectedProcess]);
      accumulateAll(unselectedProcess);
    });
  };

  const completeAllNota = () => {
    setNotaComplete(listProcess.map((item) => item.id), () => {
      setListProcess([]);
      setListAccumulate({});
    });
  };

  const modalWait = () => {
    alert("WAIT UNTIL LOADING DONE");
  };

  const completeNotaProcess = () => {
    const completeNotaTemp = [];
    listProcess.forEach((item, index) => {
      const menu = item.menu || [];
      if (menu.length > 0) {
        const completeMenuTemp = menu.filter((o) => o.isComplete === true || o.isVoid === true);
        if (completeMenuTemp.length === menu.length) completeNotaTemp.push(item);
      }
    });
    const unselectedProcess = listProcess.filter((item) => !item.isComplete);

    setNotaComplete(completeNotaTemp.map((item) => item.id), () => {
      setListProcess(() => [...[], ...unselectedProcess]);
      accumulateAll(unselectedProcess);
    });
  };

  const trigerCompleteNotaViaSocket = (notaIdList) => {
    if (notaIdList.length > 0) {
      const unselectedProcess = listProcess.filter((item) => !notaIdList.find((o) => o === item.id));
      setListProcess(() => [...[], ...unselectedProcess]);
    }
  };

  const accumulateAll = (listData) => {
    const newAccumulate = accumulateAllOrders(listData);
    setListAccumulate(newAccumulate);
  };

  const onClickMenu = async (detailData) => {
    if (!isDeleteMode) {
      if (isLoadingCompleteNota) {
        modalWait();
      } else {
        const selectedIndex = listProcess.findIndex(o => o.id === detailData.transactionId);
        if (selectedIndex !== null) {
          const listProcessTemp = listProcess;
          const selectedProcess = listProcessTemp[selectedIndex];
          const listMenu = selectedProcess.menu || [];
          const selectedIndexMenu = listMenu.findIndex(o => o.detailTransactionId === detailData.detailTransactionId);
          const selectedMenu = listMenu[selectedIndexMenu];
          notaCompleteMenuV2(
            detailData.transactionId,
            detailData.detailTransactionId,
            { isComplete: !selectedMenu.isComplete }
          );
          selectedMenu.isComplete = !selectedMenu.isComplete;
          accumulateAll(listProcessTemp);
          // SUPAYA RENDER ULANG
          setListProcess([...[], ...listProcessTemp]);
        }
      }
    }
  };

  const onClickVoid = async (detailData) => {
    const selectedIndex = listProcess.findIndex(o => o.id === detailData.transactionId);
    if (selectedIndex !== null) {
      const listProcessTemp = listProcess;
      const selectedProcess = listProcessTemp[selectedIndex];
      const listMenu = selectedProcess.menu || [];
      const selectedIndexMenu = listMenu.findIndex(o => o.id === detailData.menuId);
      const selectedMenu = listMenu[selectedIndexMenu];
      cancelMenu(
        detailData.transactionId,
        {
          menuId: detailData.menuId,
          isVoid: !selectedMenu.isVoid,
        }
      );
      selectedMenu.isVoid = !selectedMenu.isVoid;
      accumulateAll(listProcessTemp);
      setListProcess([...[], ...listProcessTemp]);
    }
  };

  return (
    <Main isLive={isConnected}>
      <NotaLayout list={listAccumulate}>
        <div className="nota-process black px-4 pb-2">
          <div className="nota-process-header">
            <div className="nota-process-title">
              PROSES {listProcess.length > 0 ? `( ${listProcess.length} )` : ""}
              {isDeleteMode && <span className="color-red-1"> - VOID MODE</span>}
            </div>
            <div className="nota-process-action">
              {/* <Button
                type="button"
                color="yellow"
                radius="xl"
                className="mr-3 px-2"
                sm
                disabled={!listProcess.find(o => o.isComplete === true)}
                loading={isLoadingCompleteNota}
                onClick={completeSelectedNota}
              >
                SELESAI YANG DIPILIH
              </Button> */}
              {/* <Button
                type="button"
                color="red"
                radius="xl"
                className="px-2 mr-2"
                sm
                onClick={() => setIsDeleteMode((value) => !value)}
              >
                <i className="resitdc icon-slash mr-1"></i>
                VOID MODE
              </Button> */}
              <Button
                type="button"
                color="blue"
                radius="xl"
                className="px-2"
                sm
                disabled={!listProcess.length > 0}
                loading={isLoadingCompleteNota}
                onClick={completeNotaProcess}
              >
                SELESAI
              </Button>
            </div>
          </div>
          <div className="nota-process-body">
            <div className="nota-process-wrapper">
              {
                isFetchingData
                  ? (<h1 className="text-center c-white mt-15 mb-15 py-3">LOADING DATA...</h1>)
                  : listProcess.length > 0
                    ? (
                      <div className="nota-process-list">
                        {
                          isFetchingData
                            ? (<h1 className="text-center c-white mt-15 mb-15 py-3">LOADING DATA...</h1>)
                            : listProcess.map((item, index) => {
                              const createdAt = item.createdAt;
                              const time = String(timeSinceIndo(dateNow, createdAt)).split(" ");
                              const timeNumber = time[0];
                              const timeLabel = time[1];
                              return (
                                <div
                                  className="process-box process-box-animation"
                                  key={index}
                                >
                                  {
                                    item.isComplete &&
                                <div
                                  className="process-box-selected"
                                  onClick={() => {
                                    onClickProcess(item.id);
                                  }}
                                >
                                  <div className="selected-icon">
                                    <i className="resitdc icon-check"></i>
                                  </div>
                                </div>
                                  }
                                  <Nota
                                    isOpenMenu={isDeleteMode}
                                    transactionId={item.id}
                                    tableNumber={item.tableNo}
                                    customerName={item.customerName}
                                    orders={item.menu}
                                    timeNumber={timeNumber}
                                    timeLabel={timeLabel}
                                    timeColor={waitingTimeColor(timeNumber, timeLabel)}
                                    // onClick={() => {
                                    //   onClickProcess(item.id);
                                    // }}
                                    onDetail={() => {
                                      openModalDetail(item.id, "process");
                                    }}
                                    onClickMenu={onClickMenu}
                                    onClickVoid={onClickVoid}
                                  />
                                </div>
                              );
                            })
                        }
                      </div>
                    )
                    : <h1 className="text-center c-white mt-15 mb-15 py-3">TIDAK ADA DATA PROSES</h1>
              }
            </div>
          </div>
        </div>
        <div className="nota-queue mt-2 px-4">
          <div className="nota-queue-header">
            <div className="nota-queue-title">
              ANTRIAN BAR / DRINK {listQueue.length > 0 ? `( ${listQueue.length} )` : ""}
            </div>
          </div>
          <div className="nota-queue-body">
            <div className="nota-queue-wrapper">
              {
                isFetchingData
                  ? (<h1 className="text-center mt-15 mb-15 py-3">LOADING DATA...</h1>)
                  : listQueue.length > 0
                    ? (
                      <div className="row">
                        {
                          listQueue.map((item, index) => {
                            const createdAt = item.createdAt;
                            const time = String(timeSinceIndo(dateNow, createdAt)).split(" ");
                            const timeNumber = time[0];
                            const timeLabel = time[1];
                            return (
                              <div className="col-nota mb-5" key={index}>
                                <Nota
                                  // isOpenMenu={isDeleteMode}
                                  tableNumber={item.tableNo}
                                  customerName={item.customerName}
                                  orders={item.menu}
                                  timeNumber={timeNumber}
                                  timeLabel={timeLabel}
                                  timeColor={waitingTimeColor(timeNumber, timeLabel)}
                                  onClick={() => {
                                    onClickQueue(item.id);
                                  }}
                                  onDetail={() => {
                                    openModalDetail(item.id, "queue");
                                  }}
                                />
                              </div>
                            );
                          })
                        }
                      </div>
                    )
                    : <h1 className="text-center mt-14 mb-14">TIDAK ADA DATA ANTRIAN</h1>
              }
            </div>
          </div>
        </div>
      </NotaLayout>
      <Modal
        show={showModalDetail}
        onClose={() => {
          setShowModalDetail(false);
        }}
      >
        <div className="px-2 py-2">
          <div className="nota-detail">
            <div className="nota-detail-header">
              <div className="display-flex align-y-center">
                <h1 className="nota-detail-header-title">
                  {detailNota.tableNo}
                </h1>
                <div className="ml-2">
                  <div className="text-weight-medium">
                    {dayjs.utc(detailNota.createdAt).format("dddd, DD MMM YYYY - HH:mm:ss")}
                  </div>
                  <div>{detailNota.customerName}</div>
                </div>
              </div>
            </div>
            <div className="nota-detail-content">
              {
                detailNota.menu &&
                detailNota.menu.length > 0
                  ? detailNota.menu.map((item, index) => (
                    <div className="nota-list" key={index}>
                      <div className="nota-list-menu">{item.name}</div>
                      <div className="nota-list-qty">{item.amount}x</div>
                    </div>
                  ))
                  : <h1 className="NO DATA MENU"></h1>
              }
            </div>
          </div>
          <Flex className="mt-3" justify="end">
            <Button color="red" onClick={() => {
              setShowModalDetail(false);
            }}
            >
              TUTUP
            </Button>
          </Flex>
        </div>
      </Modal>
    </Main>
  );
};

export default DrinkPage;
