import React from "react";
import { Navigate } from "react-router-dom";

const AuthenticatedRoute = ({
  redirectPath = "/login",
  children,
}) => {
  const userToken = localStorage.getItem("userToken");

  if (!userToken) {
    return <Navigate to={redirectPath} replace />;
  }
  return children;
};

export default AuthenticatedRoute;
